import React, { useState, useEffect } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'


import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";

import Navbar from '../inc/Navbar';



function Jhumka() {
    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [jhumka, setJhumka] = useState([])
      const navigate = useNavigate()
  
      const jhumkaCollectionRef = collection(db, "Gold_Jhumka");
      useEffect(() => {
  
          const getJhumka = async () => {
              const data = await getDocs(jhumkaCollectionRef);
              setJhumka(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getJhumka()
      }, [])
    return (
      
<>
<Navbar/>

<br/>
<ScrollToTop smooth top="100" color="maroon" />

<br/>
<br/>
<br/>
<br/>

<div className='necklacechapra'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li  className="breadcrumb-item"><Link style={{color:"darkBlue"}} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/gold" style={{color:"darkBlue"}} >Back</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{color:"darkBlue"}} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Jhumka</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

<div className="album py-3">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-2">
        
        <div className="container">

            <div className="row">
            {jhumka.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) 
    
      
       
        
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((adiradhakrishnajewellers) => { return (
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 ">
                        <img  className="card-img-top" src={adiradhakrishnajewellers.img}  alt="jhumka"/>
                            <div className="card-body">
                                
                                <h4 style={{color:"grey"}} className="card-text mb-0"> <b>{adiradhakrishnajewellers.title}</b></h4>

                                <p style={{color:"darkBlue"}}  className="card-text mb-2">{adiradhakrishnajewellers.des}</p>

                                <div className="d-flex justify-content-between align-items-center mb-0">
                              
                              <a href='tel: 9732666583' className="chaprabutton mb-1">Call Book</a>
                              <ReactWhatsapp number="+91 9732666583" className="chaprabutton mb-1" message="I want to buy this shirt, contact me"> Whatsapp Book</ReactWhatsapp>
                          
                           </div>
                         
                     
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={dul.length}
                /> */}


                            </div>
                            </div>

</>
        
        );
    }
    
    export default Jhumka;



