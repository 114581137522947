import React from 'react'
import Navbar from '../inc/Navbar'
import Slider from '../inc/Slider'
import "./Home.css";
import Diamondphoto from "../images/diamond.jpg";
import Gold from "../images/necklace-gold.jpg";
import {Link} from "react-router-dom";
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import Ganesh from "../images/ganesh-silvr.jpg";
import ScrollToTop from "react-scroll-to-top";
// import Necklace from "../images/necklace-gold.jpg"
import Mukut from "../images/mukut.mp4";

import Necklacevideo from "../images/necklace.mp4"
import Latest from './Latest';
import Mantasa from "../images/mantasa.jpg";
import Update from './Update';
function Home() {
  return (
    <div>

      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br/>
      <ScrollToTop smooth top="100" color="darkBlue"/>
      <Slider />
      <div className='marqueeecolor'>
      <div className='album py-0'>
        <div className='container'>
        <marquee><h5 className='mt-2' style={{color:"white"}}>Welcome To Adi Radhakrishna jewellers | Chapra, Pin-741123, Dist- Nadia </h5></marquee>
        </div>
        </div>
      </div>
      <br />
      <Update/>
      
      <div className='album py-2'>
        <div className='container'>
          <div className='chapra'>
          <center>
            <h4>ADI RADHAKRISHNA JEWELLERS Exclusive Collection</h4>
          </center>
          </div>
          <hr/>
          </div>
        </div>
      <div className='album py-2'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/Diamond">
                <img src={Diamondphoto} className="card-img-top" alt="adiradhakrishna jewellers"/>
                </Link>
                <div className="card-body">
                  <center>
                  <h5 className="card-title">Diamond</h5>
                  <p className="card-text">Latest Diamond Design Available at Adi Radhakrisha jewellers</p>
                  <a href="Diamond" className="cardbutton">Learn More</a>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/Gold">
                <img src={Gold} className="card-img-top" alt="Gold" />
                </Link>
                <div className="card-body">
                  <center>
                  <h5 className="card-title">Gold</h5>
                  <p className="card-text">Latest Gold Design Available at Adi Radhakrisha jewellers</p>
                  <a href="GOld" className="cardbutton">Learn More</a>
                  </center>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-2">
              <div className="card" >
                <Link to="/Silverpage">
                <img src={Ganesh} className="card-img-top" alt="silver"/>
                </Link>
                <div className="card-body">
                  <center>
                  <h5 className="card-title">Silver</h5>
                  <p className="card-text">Latest Silver Design Available at Adi Radhakrisha jewellers</p>
                  <a href="Silverpage" className="cardbutton">Learn More</a>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mt-3">
              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={Mukut} height="250px" type="video/webm" />
              </Video>
            </div>
            <div className="col-md-6 mt-3">

              <Video Play muted loop onCanPlayThrough={() => {
                console.log('video play')
              }}>
                <source src={Necklacevideo} height="250px" type="video/webm" />
              </Video>
            </div>
          </div>
        </div>
      </div>
<br/>

<hr/>
<br/>
<div className="album py-1">
        <div className="container">
          <h4 >Latest Design </h4> 
          </div>
          </div>
<Latest/>
<br/>

<br/>

<div className='radius'>
<div className="album py-1">
        <div className="container">

<img src={Mantasa} className="d-block w-100" alt="adiradhakrishnajewellers"/>
</div>
</div>
</div>
<br/>
<br/>

      <div className="album py-1">
        <div className="container">
          <div className="row">
            <iframe src="https://www.google.com/maps/embed?pb=!4v1713606876339!6m8!1m7!1s7mnK1KFRy7TSBoyULQ90ig!2m2!1d23.52501425622392!2d88.54808864773656!3f121.38000439084455!4f-1.0048965186615249!5f0.8716622596838739" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
      <br />

    </div>
  )
}

export default Home