
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyAgG4eZEiVqmb_6AtlzsjUq-YTCXbmEkqM",
  authDomain: "adi-radhakrishna.firebaseapp.com",
  projectId: "adi-radhakrishna",
  storageBucket: "adi-radhakrishna.appspot.com",
  messagingSenderId: "242359058954",
  appId: "1:242359058954:web:248849f11d88d184e755f3",
  measurementId: "G-8J575RTZT9"

};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;