import React from 'react'
import Navbar from "../inc/Navbar";
import Necklace from "../images/nacklace.jpg";
import { Link } from "react-router-dom";
import Jhumka from "../images/jhumka.jpg";
import Ring from "../images/rings.jpg";
import Dul from "../images/dul.jpg";
import Pola from "../images/pola.jpg";
import Chur from "../images/chur.jpg";
import Bracelet from "../images/bracelet.jpg";
import ScrollToTop from "react-scroll-to-top";
import Sankha from "../images/sankha-page.jpg";

function Gold() {
  return (
    <div>

      <Navbar />
      <br />
      <br />
      <br />
      <br />
      <br />
      <ScrollToTop smooth top="100" color="darkBlue" />
      <div className="krishnagarrestaurant">
        <div className="album py-1">

          <div className="container mt-3">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <br />
      <div className='album py-2'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/Necklace">
                  <img src={Necklace} className="card-img-top" alt="adiradhakrishna jewellers" />
                  <div className="card-body">

                    <h5 style={{ color: "black" }} className="card-title">Necklace</h5>

                    <a href="" className="cardbutton">Learn More</a>

                  </div>
                </Link>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/Jhumka">
                  <img src={Jhumka} className="card-img-top" alt="jhumka" />
                </Link>
                <div className="card-body">

                  <h5 className="card-title">Jhumka</h5>

                  <a href="Jhumka" className="cardbutton">Learn More</a>

                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/Ring">
                  <img src={Ring} className="card-img-top" alt="ring" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Ring</h5>
                  <a href="Ring" className="cardbutton">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/Dul">
                  <img src={Dul} className="card-img-top" alt="Dul" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Dul</h5>
                  <a href="Dul" className="cardbutton">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/pola">
                  <img src={Pola} className="card-img-top" alt="Pola" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Pola</h5>
                  <a href="pola" className="cardbutton">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <a href='chur'>
                  <img src={Chur} className="card-img-top" alt="Chur" />
                </a>
                <div className="card-body">
                  <h5 className="card-title">Chur</h5>
                  <a href="chur" className="cardbutton">Learn More</a>

                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <a href='Mantasa'>
                  <img src={Bracelet} className="card-img-top" alt="Bracelet" />
                </a>
                <div className="card-body">
                  <h5 className="card-title">Mantasa</h5>
                  <a href="Mantasa" className="cardbutton">Learn More</a>
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <a href='Sankha'>
                  <img src={Sankha} className="card-img-top" alt="Sankha" />
                </a>
                <div className="card-body">
                  <h5 className="card-title">Sankha</h5>
                  <a href="Sankha" className="cardbutton">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

    </div>
  )
}

export default Gold