import './App.css';

import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';


import Home from "./components/pages/Home";
import Footer from "./components/inc/Footer";
import Contact from './components/pages/Contact';
import Silverpage from './components/pages/Silverpage';
import Gold from './components/pages/Gold';
import Necklace from './components/pages/Necklace';
import Jhumka from './components/pages/Jhumka';
import Ring from './components/pages/Ring';
import Dul from './components/pages/Dul';
import Pola from './components/pages/Pola';
import Chur from './components/pages/Chur';
import Bracelet from './components/pages/Bracelet';
import Diamond from './components/pages/Diamond';
import Diamondring from './components/pages/Diamondring';
import Diamondnecklace from './components/pages/Diamondnecklace';
import Sankha from './components/pages/Sankha';
function App() {
  return (
    <Router>
   
    <div>
      
      <Routes>

      <Route axact path="/" element={<Home/>}/>
      <Route axact path="/Contact" element={<Contact/>}/>
      <Route axact path="/Silverpage" element={<Silverpage/>}/>
      <Route axact path="/Gold" element={<Gold/>}/>
      <Route axact path="/Necklace" element={<Necklace/>}/>
      <Route axact path="/Jhumka" element={<Jhumka/>}/>
      <Route axact path="/Ring" element={<Ring/>}/>
      <Route axact path="/Dul" element={<Dul/>}/>
      <Route axact path="/pola" element={<Pola/>}/>
      <Route axact path="/chur" element={<Chur/>}/>
      <Route axact path="/Mantasa" element={<Bracelet/>}/>
      <Route axact path="/Diamond" element={<Diamond/>}/>
      <Route axact path="/Diamondring" element={<Diamondring/>}/>
      <Route axact path="/Diamondnecklace" element={<Diamondnecklace/>}/>
      <Route axact path="/Sankha" element={<Sankha/>}/>
      </Routes>
    <Footer/>
  
    </div>

    </Router>
  );
}

export default App;
