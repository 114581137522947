import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import { db } from '../../firebase'


import ReactWhatsapp from "react-whatsapp";
import ScrollToTop from "react-scroll-to-top";

import Navbar from '../inc/Navbar';




function Silverpage() {
    const [setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showPerPage, setShowPerPage] = useState(58);
    const [pagination, setPagination] = useState({
        start: 0,
        end: showPerPage,
    });

    const onPaginationChange = (start, end) => {
        setPagination({ start: start, end: end });
    };
    const [searchTerm, setsearchTerm] = useState("");
    const [silverpage, setSilverpage] = useState([])
    const navigate = useNavigate()

    const silverpageCollectionRef = collection(db, "Silver_Page");
    useEffect(() => {

        const getSilverpage = async () => {
            const data = await getDocs(silverpageCollectionRef);
            setSilverpage(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
        }

        getSilverpage()
    }, [])
    return (

        <>
            <Navbar/>

            <br />
            <ScrollToTop smooth top="100" color="maroon" />

            <br />
            <br />
            <br />
            <br/>

            <div className='necklacechapra'>
                <div className='album py-1'>
                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link style={{ color: "darkBlue" }} to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/contact" style={{ color: "darkBlue" }} >Contact Us</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Silver </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>

            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">

                            <div className="card-body">
                                <input type="text" className="form-control" placeholder="Search" name="location"
                                    onChange={(e) => {
                                        setsearchTerm(e.target.value);
                                    }}
                                />
                                <div className="d-flex justify-content-between align-items-center">




                                </div>


                            </div>


                        </div>

                    </div>

                </div>
            </div>
            <div className="album py-2">

                <div className="container">

                    <div className="row">
                        {silverpage.filter((val) => {
                            if (setsearchTerm === "") {
                                return val;
                            } else if (
                                val.title.toLowerCase().includes(searchTerm.toLowerCase())




                            ) {
                                return val;
                            }
                        }).slice(pagination.start, pagination.end).map((adiradhakrishnajewellers) => {
                            return (
                                <div className="col-md-4 mt-8" >
                                    <div className="card mb-4 ">
                                        <img className="card-img-top" src={adiradhakrishnajewellers.img} alt="silver" />
                                        <div className="card-body">

                                            <h6 className="card-text mb-2"> <b>{adiradhakrishnajewellers.title}</b></h6>

                                            <p className="card-text mb-2">{adiradhakrishnajewellers.des}</p>

                                            <div className="d-flex justify-content-between align-items-center">

                                                <a href='tel: 9732666583' className="chaprabutton mt-2">Call Book</a>
                                                <ReactWhatsapp number="+91 9732666583" className="chaprabutton mt-2" message="I want to buy this shirt, contact me"> Whatsapp Book</ReactWhatsapp>

                                            </div>

                                            <div className="d-flex justify-content-between align-items-center">



                                            </div>
                                        </div>
                                    </div>

                                </div>
                            )
                        })}
                    </div>
                    {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={dul.length}
                /> */}


                </div>
            </div>

        </>

    );
}

export default Silverpage;



