import React from 'react'
import Homebanner from "../images/radhakrsihna_jewellers.jpg";
import sankha from "../images/SANKHA-HOME.jpg";
import Necklace from "../images/necklace_banner.jpg";
import Bracelet from "../images/mantasa.jpg";


function Slider() {
  return (
    <div>
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={sankha} className="d-block w-100" alt="adiradhakrishnajewellers"/>
    </div>
    <div className="carousel-item">
      <img src={Necklace} className="d-block w-100" alt="no1jewellerschapra"/>
    </div>
    <div className="carousel-item">
      <img src={Bracelet} className="d-block w-100" alt="adiradhakrishnajewellers"/>
    </div>
    <div className="carousel-item">
      <img src={Homebanner} className="d-block w-100" alt="bestjewellers showroom in chapra"/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
    </div>
  )
}

export default Slider