import React from 'react'
import Navbar from "../inc/Navbar";
// import Necklace from "../images/nacklace.jpg";
import {Link} from "react-router-dom";
import Ringdiamond from "../images/ring-diamond.jpg";
import ScrollToTop from "react-scroll-to-top";

import Necklace from "../images/necklace Diamond.jpg";


function Diamond() {
  return (
    <div>
        
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br />
        <br/>
        <ScrollToTop smooth top="100" color="darkBlue"/>
        
        <div className="krishnagarrestaurant">
                <div className="album py-1">

                    <div className="container mt-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br/>
      <div className='album py-2'>
        <div className='container'>
          <div className='row'>
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/Diamondring">
                <img src={Ringdiamond} className="card-img-top" alt="adiradhakrishna diamond" />
                <div className="card-body">
                
                  <h5 style={{color:"black"}} className="card-title">Ring Diamond</h5>
                
                  <a href="Diamondring" className="cardbutton">Learn More</a>
               
                </div>
                </Link>
              </div>
            </div>
           
         
            <div className="col-md-3 mt-3">
              <div className="card shadow" >
                <Link to="/Diamondnecklace">
                <img src={Necklace} className="card-img-top" alt="Necklace" />
                </Link>
                <div className="card-body">
                  <h5 className="card-title">Necklace Diamond</h5>
                  <a href="Diamondnecklace" className="cardbutton">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
      <br/>

    </div>
  )
}

export default Diamond